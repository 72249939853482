// StayTuned.tsx
import React, { useState, useEffect } from 'react';
import './StayTuned.css';
import CountdownTimer from './CountdownTimer';

interface StayTunedProps {
  launchDateTime: string; // ISO 8601 format: "YYYY-MM-DDTHH:mm:ss"
}

const StayTuned: React.FC<StayTunedProps> = ({ launchDateTime }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(launchDateTime) - +new Date();
    let timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [launchDateTime]);

  return (
    <div className='wrapper'>
      <div className="stay-tuned-container">
        <h1>Stay Tuned!</h1>
        <p>We are working on something amazing.</p>
        <CountdownTimer
          initialDays={timeLeft.days}
          initialHours={timeLeft.hours}
          initialMinutes={timeLeft.minutes}
          initialSeconds={timeLeft.seconds}
        />
      </div>
      <div className="copyright">&copy; 2025 artfolio. All rights reserved.</div>
    </div>

  );
};

export default StayTuned;